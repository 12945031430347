export const environment = {
	production: false,
	envName: 'dev',

	// Authentication
	clientId: '9f8b4fe5-71e1-4ae5-8f40-a0b220fba7a6',
	openidConfUrl:
		'https://id-ip-stage.zeiss.com/CD/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdAADOnlySignIn',

	// REST API:
	baseUrlEsb: 'https://esb-dev.zeiss.com/public/api/experience/vis/orderdetails/dev/',
	ocpApimSubscriptionKey: '14eadb65d14c44b8b457d3df7da4ba70',
	apiAuthorization:
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTg5NzA3Mzk0IiwiY3JlYXRlIjp0cnVlfQ.5Aq8GUbYQPFvmj44wZjw_d1a45EbN8e6UKOIzlTe_kg',

	// BLOB Access:
	blobAccessKey:
		'?sv=2022-11-02&ss=b&srt=sco&sp=r&se=2027-02-03T21:21:05Z&st=2025-02-03T13:21:05Z&spr=https&sig=eYK718S%2B7RerwdP8ibYrq6FuUFodS%2Fwskcr5Ud0tGoU%3D',
	lmtDetailUrl: 'http://adeerl01ilx004.zeiss.org/lmt-client/#/detail?ediCode=',
};
