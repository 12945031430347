// prettier-ignore
// this file is automatically generated by package-info.ts script
export const Build = {
  appVersion: `3.0.0`,
  branch: `DOWNGRADE/ANGULAR-14`,
  lastCommit: `f158e6a`,
  lastCommitUrl: `https://zeissgroup.visualstudio.com/LensOrderDB/_git/Zeiss.Vision.Digital.Portal.Frontend/commit/f158e6a27f165473119a8af2ceaafb497ca70d1c`,
  lastCommitHash: `f158e6a27f165473119a8af2ceaafb497ca70d1c`,
  lastCommitTime: `2025-02-03T20:19:31+01:00`,
  lastCommitMessage: `fixed scripts`,
  lastCommitAuthor: `David Hille`
};
