<header>
	<loi-navbar></loi-navbar>

	<mat-progress-bar
		*ngIf="progress.show"
		mode="indeterminate"
		style="height: 2px; position: fixed; top: 46px; z-index: 99999"
	></mat-progress-bar>
</header>

<simple-notifications [options]="options"></simple-notifications>

<main class="main-container" style="height: 100%">
	<router-outlet></router-outlet>
</main>

<footer class="small text-center" style="clear: both">
	<p>
		<small>
			Copyright © 2025 ZEISS. {{ 'COMMON.allRightsReserved' | translate }}
			<br />
			loi-client | {{ environmentName }} | {{ build.appVersion }} | {{ build.branch }} |
			{{ build.lastCommitTime | visDate }}
		</small>
	</p>
</footer>
